import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Account = () => (
  <HowToLayout>
    <h2>Account</h2>
  </HowToLayout>
);

export default Account;
